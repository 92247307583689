import React, { useState, useEffect } from "react";
import { FormatDateTime } from "../../../functions/ddmmyyyy";
import * as DOMPurify from "dompurify";
import axios from "axios";
import parse from "html-react-parser";

import "../../../css/ticketEmails.css";

const TicketEmailsSection = ({ ticket }) => {
  const [emails, setEmails] = useState(null);

  const getTicketEmails = async () => {
    await axios.get(`/api/emails/${ticket._id}`).then((response) => {
      setEmails(response.data);
    });
  };

  useEffect(() => {
    getTicketEmails();

    //eslint-disable-next-line
  }, []);

  const renderHTMLBody = (email) => {
    let clean = DOMPurify.sanitize(email);

    return clean;
  };

  return (
    <div className="portal_ticket_emails_container middle_card">
      {emails !== null ? (
        emails.map((email) => (
          <div key={email._id}>
            <div className="portal_email_detail_container">
              <div className="portal_email_detail_title_container">
                <div className="portal_email_detail">
                  Date: {FormatDateTime(email.receivedDateTime)}
                </div>
              </div>
              <div className="portal_email_detail">
                To: {email.toRecipients}
              </div>
              <div className="portal_email_detail">
                CC: {email.ccRecipients}
              </div>
              <div className="portal_email_detail">From: {email.sender}</div>
              <div className="portal_email_text">SUBJECT: {email.subject}</div>
              {/* <div className="portal_email_text">{email.bodyPreview}</div> */}
              <div className="portal_email_text">
                {parse(renderHTMLBody(email.body))}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div> NO emails </div>
      )}
    </div>
  );
};

export default TicketEmailsSection;
