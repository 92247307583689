import React from "react";
import Header from "../../components/Header";

const TicketSubmitted = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const ticketNum = queryParameters.get("ticket");

  return (
    <>
      <Header />
      <div className="body">
        <section className="ticket_confirmation_container">
          <div className="hero_title_container">
            <div className="hero-header">
              <div className="hero-title">
                <h1 className="heading">Support Ticket Received</h1>
              </div>
            </div>
          </div>
          <div className="hero-container">
            <div className="ticket_confirmation_text-container">
              <div className="ticket_confirmation_text">
                Thank you for contacting us. We have assigned support ticket #{" "}
                {ticketNum} to your case.  We will send you a confirmation via
                email and will be contacting you as soon as possible.
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default TicketSubmitted;
