import React, { useEffect, useState, useContext } from "react";
import { Navigate } from "react-router";
import AlertContext from "../../context/alert/alertContext";
import AuthContext from "../../context/auth/authContext";
import Logo from "../../images/abt-logo---150x90px.png";
import axios from "axios";

const Login = () => {
  const { setAlert } = useContext(AlertContext);
  const { isAuthenticated, authPin, error, clearErrors } =
    useContext(AuthContext);
  const [authenticationState, setAuthenticationState] = useState("login");
  const [login, setLogin] = useState({
    UserName: "",
    Password: "",
  });
  const [pin, setPin] = useState({
    tempPin: null,
  });

  useEffect(() => {
    if (isAuthenticated) {
      setAuthenticationState("authenticated");
    }
    if (error === "PIN EXPIRED") {
      console.log("Pin Expired - Login Again");
      setAuthenticationState("login");
      clearErrors();
    }
    if (error === "INVALID PIN") {
      console.log("Invalid Pin - Try Again");
      clearErrors();
    }

    //eslint-disable-next-line
  }, [isAuthenticated, error]);

  const onChange = (e) => setPin({ ...pin, tempPin: e.target.value });

  const handleInput = (e) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };

  const submit = async (e) => {
    e.preventDefault();
    if (login.UserName === "" || login.Password === "") {
      console.log("Missing Information");
      // setAlert("Please fill in all fields", "OK", "error");
    } else {
      try {
        const res = await axios.post("/api/auth", login);
        if (res.status === 200) {
          setAuthenticationState("pin");
        }
      } catch (error) {
        // setAlert(true, "Invalid User & Password", "error");
        console.log("Invalid User");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (pin === "") {
      console.log("Please Enter Temporary Pin from Email");
    } else if (pin.tempPin.length === 6) {
      authPin(pin);
    } else {
      console.log("Invalid Pin - Please Re-enter pin with no spaces");
    }
  };

  switch (authenticationState) {
    default:
    case "login":
      return (
        <div className="body login_body">
          <div className="login_content_container">
            <div className="login_container">
              <div className="login_title">
                <img src={Logo} alt="AB&T Telecom" className="login_logo" />
                <h2 className="login_title">SUPPORT PORTAL</h2>
              </div>
              <form className="login_form" onSubmit={submit}>
                <div>
                  <input
                    type="text"
                    name="UserName"
                    placeholder="User Name"
                    className="login_input"
                    onChange={handleInput}
                  />
                </div>
                <div>
                  <input
                    type="password"
                    name="Password"
                    placeholder="Password"
                    className="login_input"
                    onChange={handleInput}
                  />
                </div>
                <button
                  type="submit"
                  className="login_button"
                  onClick={submit}
                  autoFocus
                >
                  LOGIN
                </button>
              </form>
            </div>
          </div>
        </div>
      );
    case "pin":
      return (
        <div className="body login_body">
          <div className="login_content_container">
            <div className="login_container">
              <div className="login_title">
                <h2 className="login_title">SUPPORT PORTAL</h2>
              </div>
              <div className="login_form">
                <div>
                  <label htmlFor="pin">Enter Temp Security Pin</label>
                  <input
                    type="text"
                    name="pin"
                    placeholder="Enter Temp Pin"
                    className="login_input"
                    onChange={onChange}
                  />
                </div>
                <div></div>
                <button
                  type="submit"
                  className="login_button"
                  onClick={handleSubmit}
                  autoFocus
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      );

    case "authenticated":
      return <Navigate to="/portal" />;
  }
};

export default Login;
