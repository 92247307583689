import axios from "axios";

export const FetchUsers = async () => {
  let data = {};

  try {
    await axios.get("/api/users/all").then((res) => {
      data = res.data;
    });
  } catch (err) {
    console.log(err);
    data = "error";
  }
  return data;
};
export const FetchUser = async (id) => {
  let data = {};

  try {
    await axios.get(`/api/user/${id}`).then((res) => {
      data = res.data;
    });
  } catch (err) {
    console.log(err);
    data = "error";
  }
  return data;
};

export const DeleteUser = async (id) => {
  let data = {};

  try {
    await axios.delete(`/api/user/${id}`).then((res) => {
      data = res.data;
    });
  } catch (err) {
    console.log(err);
    data = "error";
  }
  return data;
};
export const EditUser = async (id) => {
  let data = {};

  try {
    await axios.put(`/api/user/${id}`).then((res) => {
      data = res.data;
    });
  } catch (err) {
    console.log(err);
    data = "error";
  }
  return data;
};
export const CreateUser = async (user) => {
  let data = {};

  try {
    await axios.post(`/api/user/`, user).then((res) => {
      data = res.data;
    });
  } catch (err) {
    console.log(err);
    data = "error";
  }
  return data;
};
