import React, { useEffect, useState } from "react";

import "../../../css/configTable.css";

const EcConfigTable = ({ configs, getAconfig, filter, setFilter }) => {
  const [searchBar, setSearchBar] = useState("");
  const [displayedConfigs, setDisplayedConfigs] = useState([]);

  const search = () => {
    if (configs !== null && configs !== undefined) {
      const searchResults = configs.filter((config) => {
        const regex = new RegExp(`${searchBar}`, "gi");

        return (
          config.Acct.match(regex) ||
          config.CompanyName.match(regex) ||
          config.SiteAddress.match(regex) ||
          config.Provider.match(regex) ||
          config._id === parseInt(searchBar)
        );
      });
      setDisplayedConfigs(searchResults);
    }
  };

  useEffect(() => {
    search();

    //eslint-disable-next-line
  }, [searchBar]);

  useEffect(() => {
    search();

    //eslint-disable-next-line
  }, [configs]);

  const searchConfigs = (e) => {
    setSearchBar(e.target.value);
  };

  return (
    <>
      <div className="portal_body">
        <div className="portal_configs_container">
          <div className="portal_config_filter_container">
            <div className="portal_config_filter_column">
              <label
                htmlFor="config_Open"
                className="portal_config_filter_label"
              >
                Search
              </label>
              <input
                type="text"
                className="portal_config_filter_search"
                maxLength="256"
                name="Search_Bar"
                id="Search_Bar"
                onChange={searchConfigs}
              />
            </div>
            <div className="portal_config_filter_column"></div>
            <div className="portal_config_filter_column"></div>
          </div>
          <div className="portal_config_table_container">
            <div className="portal_config_table_header_row">
              <div className="portal_config_table_header_item">Account</div>
              <div className="portal_config_table_header_item">Company</div>
              <div className="portal_config_table_header_item">Location</div>
              <div className="portal_config_table_header_item">
                Site Address
              </div>
              <div className="portal_config_table_header_item">IP Address</div>
              <div className="portal_config_table_header_item">Provider</div>
            </div>
            {displayedConfigs.length > 0
              ? displayedConfigs.map((config) => (
                  <div
                    className="portal_config_data_row"
                    key={config._id}
                    onClick={() => getAconfig(config._id)}
                  >
                    <div className="portal_config_data_item">{config.Acct}</div>
                    <div className="portal_config_data_item">
                      {config.CompanyName}
                    </div>
                    <div className="portal_config_data_item">
                      {config.Location_Name}
                    </div>
                    <div className="portal_config_data_item">
                      {config.SiteAddress}
                    </div>
                    <div className="portal_config_data_item">
                      {config.StaticIP}
                    </div>
                    <div className="portal_config_data_item">
                      {config.Provider}
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
        ;
      </div>
    </>
  );
};

export default EcConfigTable;
