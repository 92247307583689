import { useContext } from "react";
import UsersContext from "../context/users/usersContext";

const UserLookup = (id) => {
  const { users } = useContext(UsersContext);
  const userData = users.filter((user) => user._id === id);
  const name = userData[0].FirstName + " " + userData[0].LastName;
  return name;
};

export default UserLookup;
