import React, { useState } from "react";
import ConfigTopCard from "./ConfigTopCard";
import ConfigMiddleCard from "./ConfigMiddleCard";
import ConfigButtons from "./ConfigButtons";
import axios from "axios";

import "../../../css/configDetail.css";

const EcConfigDetail = ({ config, setconfig, setPage, getConfigs }) => {
  const [editMode, setEditMode] = useState(false);
  const [configEdits, setConfigEdits] = useState({});

  const saveEdits = async () => {
    try {
      const res = await axios.put(`/api/ecconfig/${config._id}`, configEdits);
      if (res.status === 200) {
        setEditMode(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdits = (e) => {
    setConfigEdits({ ...configEdits, [e.target.name]: e.target.value });
  };

  return (
    <div className="portal_body">
      <ConfigButtons
        setPage={setPage}
        config={config}
        getConfigs={getConfigs}
        editMode={editMode}
        setEditMode={setEditMode}
        saveEdits={saveEdits}
      />
      <ConfigTopCard
        config={config}
        editMode={editMode}
        handleEdits={handleEdits}
      />
      <ConfigMiddleCard
        config={config}
        editMode={editMode}
        handleEdits={handleEdits}
      />
      {/* <TicketDetailsCard
            ticket={ticket}
            editMode={editMode}
            handleEdits={handleEdits}
          /> */}
      {/* <TicketNotesSection
            ticket={ticket}
            editMode={editMode}
            handleEdits={handleEdits}
          /> */}
    </div>
  );
};

export default EcConfigDetail;
