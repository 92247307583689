import React from "react";

const ConfigMiddleCard = ({ config, editMode, handleEdits }) => {
  return (
    <div className="portal_config_card middle_card">
      <div className="portal_config_detail_top_container">
        <div className="portal_config_detail_left_container">
          <div className="portal_config_detail_row">
            <div className="portal_config_field_name">PROVIDER:</div>
            <div className="portal_config_field_value_container">
              <input
                type="text"
                maxLength="256"
                name="Provider"
                id="Provider"
                className={
                  editMode
                    ? "portal_config_field_value_editable"
                    : "portal_config_field_value"
                }
                defaultValue={config.Provider}
                readOnly={!editMode}
                onChange={handleEdits}
              />
            </div>
          </div>
          <div className="portal_config_detail_row">
            <div className="portal_config_field_name">RATE PLAN:</div>
            <div className="portal_config_field_value_container">
              <input
                type="text"
                maxLength="256"
                name="RatePlan"
                id="RatePlan"
                className={
                  editMode
                    ? "portal_config_field_value_editable"
                    : "portal_config_field_value"
                }
                defaultValue={config.RatePlan}
                readOnly={!editMode}
                onChange={handleEdits}
              />
            </div>
          </div>
          <div className="portal_config_detail_row">
            <div className="portal_config_field_name">APN:</div>
            <div className="portal_config_field_value_container">
              <input
                type="text"
                maxLength="256"
                name="APN"
                id="APN"
                className={
                  editMode
                    ? "portal_config_field_value_editable"
                    : "portal_config_field_value"
                }
                defaultValue={config.APN}
                readOnly={!editMode}
                onChange={handleEdits}
              />
            </div>
          </div>
          <div className="portal_config_detail_row">
            <div className="portal_config_field_name">MOBILE #:</div>
            <div className="portal_config_field_value_container">
              <input
                type="text"
                maxLength="256"
                name="MobileID"
                id="MobileID"
                className={
                  editMode
                    ? "portal_config_field_value_editable"
                    : "portal_config_field_value"
                }
                defaultValue={config.MobileID}
                readOnly={!editMode}
                onChange={handleEdits}
              />
            </div>
          </div>
        </div>
        <div className="portal_config_detail_right_container">
          <div className="portal_config_detail_row">
            <div className="portal_config_field_name">IP ADDRESS:</div>
            <div className="portal_config_field_value_container">
              <input
                type="text"
                maxLength="256"
                name="StaticIP"
                id="StaticIP"
                className={
                  editMode
                    ? "portal_config_field_value_editable"
                    : "portal_config_field_value"
                }
                defaultValue={config.StaticIP}
                readOnly={!editMode}
                onChange={handleEdits}
              />
            </div>
          </div>
          <div className="portal_config_detail_row">
            <div className="portal_config_field_name">SUBNET:</div>
            <div className="portal_config_field_value_container">
              <input
                type="text"
                maxLength="256"
                name="Subnet"
                id="Subnet"
                className={
                  editMode
                    ? "portal_config_field_value_editable"
                    : "portal_config_field_value"
                }
                defaultValue={config.Subnet}
                readOnly={!editMode}
                onChange={handleEdits}
              />
            </div>
          </div>
          <div className="portal_config_detail_row">
            <div className="portal_config_field_name">GATEWAY:</div>
            <div className="portal_config_field_value_container">
              <input
                type="text"
                maxLength="256"
                name="Gateway"
                id="Gateway"
                className={
                  editMode
                    ? "portal_config_field_value_editable"
                    : "portal_config_field_value"
                }
                defaultValue={config.Gateway}
                readOnly={!editMode}
                onChange={handleEdits}
              />
            </div>
          </div>
          <div className="portal_config_detail_row">
            <div className="portal_config_field_name">SIM:</div>
            <div className="portal_config_field_value_container">
              <input
                type="text"
                maxLength="256"
                name="SIM"
                id="SIM"
                className={
                  editMode
                    ? "portal_config_field_value_editable"
                    : "portal_config_field_value"
                }
                defaultValue={config.SIM}
                readOnly={!editMode}
                onChange={handleEdits}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigMiddleCard;
