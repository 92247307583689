import React, { useContext, useEffect, useState } from "react";
import { FormatDate } from "../../../functions/ddmmyyyy";
import UsersContext from "../../../context/users/usersContext";

import "../../../css/ticketTable.css";

const TicketsTable = ({
  tickets,
  getATicket,
  filter,
  setFilter,
  setContent,
}) => {
  const { users } = useContext(UsersContext);
  const [searchBar, setSearchBar] = useState("");
  const [displayedTickets, setDisplayedTickets] = useState([]);
  const handleOpenStatus = (e) => {
    if (e.target.value === "true") {
      setFilter({ Ticket_Open: true });
      document.getElementById("Ticket_Status").value = "all";
      document.getElementById("Search_Bar").value = "";
      setSearchBar("");
    } else if (e.target.value === "all") {
      setFilter({});
      document.getElementById("Ticket_Status").value = "all";
      document.getElementById("Search_Bar").value = "";
      setSearchBar("");
    } else {
      setFilter({ Ticket_Open: false });
      document.getElementById("Ticket_Status").value = "all";
      document.getElementById("Search_Bar").value = "";
      setSearchBar("");
    }
  };

  const handleStatus = (e) => {
    if (e.target.value === "all") {
      setFilter({ Ticket_Open: filter.Ticket_Open });
    } else {
      setFilter({ ...filter, Ticket_Status: e.target.value });
    }
  };

  const search = () => {
    if (tickets !== null && tickets !== undefined) {
      const searchResults = tickets.filter((ticket) => {
        const regex = new RegExp(`${searchBar}`, "gi");

        return (
          ticket.Ticket_Contact_Name.match(regex) ||
          ticket.Ticket_Contact_Company.match(regex) ||
          ticket.Ticket_Contact_Email.match(regex) ||
          ticket.Ticket_Contact_Phone.match(regex) ||
          ticket._id === parseInt(searchBar)
        );
      });
      setDisplayedTickets(searchResults);
    }
  };

  useEffect(() => {
    search();

    //eslint-disable-next-line
  }, [searchBar]);

  useEffect(() => {
    search();

    //eslint-disable-next-line
  }, [tickets]);

  const searchTickets = (e) => {
    setSearchBar(e.target.value);
  };

  const userLookup = (id) => {
    const userData = users.filter((user) => user._id === id);
    const name = userData[0].FirstName + " " + userData[0].LastName;
    return name;
  };

  return (
    <>
      <div className="portal_body">
        <div className="portal_tickets_container">
          <div className="portal_ticket_newTicket_container">
            <div
              className="portal_newTicket_button"
              onClick={() => setContent("newTicket")}
            >
              NEW
            </div>
          </div>
          <div className="portal_ticket_filter_container">
            <div className="portal_ticket_filter_column">
              <label
                htmlFor="Ticket_Open"
                className="portal_ticket_filter_label"
              >
                Search
              </label>
              <input
                type="text"
                className="portal_ticket_filter_search"
                maxLength="256"
                name="Search_Bar"
                id="Search_Bar"
                onChange={searchTickets}
              />
            </div>
            <div className="portal_ticket_filter_column">
              <label
                htmlFor="Ticket_Open"
                className="portal_ticket_filter_label"
              >
                Ticket Status
              </label>
              <select
                id="Ticket_Status"
                name="Ticket_Status"
                defaultValue={filter.Ticket_Status}
                onChange={handleStatus}
                className="portal_ticket_Select_input"
              >
                <option value="all">All</option>
                <option value="Unassigned">Unassigned</option>
                <option value="Working">Working</option>
                <option value="Pending Customer">Pending Customer</option>
                <option value="Pending Vendor">Pending Vendor</option>
                <option value="On Hold">On Hold</option>
              </select>
            </div>
            <div className="portal_ticket_filter_column">
              <label
                htmlFor="Ticket_Open"
                className="portal_ticket_filter_label"
              >
                Ticket Open
              </label>
              <select
                id="Ticket_Open"
                name="Ticket_Open"
                onChange={handleOpenStatus}
                className="portal_ticket_Select_input"
                defaultValue={filter.Ticket_Status}
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
                <option value={"all"}>Show All Tickets</option>
              </select>
            </div>
          </div>
          <div className="portal_ticket_table_container">
            <div className="portal_ticket_table_header_row">
              <div className="portal_ticket_table_header_item">Ticket #</div>
              <div className="portal_ticket_table_header_item">Contact</div>
              <div className="portal_ticket_table_header_item">Company</div>
              <div className="portal_ticket_table_header_item">Status</div>
              <div className="portal_ticket_table_header_item">OWNER</div>
              <div className="portal_ticket_table_header_item">Date</div>
            </div>
            {displayedTickets.length > 0
              ? displayedTickets.map((ticket) => (
                  <div
                    className="portal_ticket_data_row"
                    key={ticket._id}
                    onClick={() => getATicket(ticket._id)}
                  >
                    <div className="portal_ticket_data_item">{ticket._id}</div>
                    <div className="portal_ticket_data_item">
                      {ticket.Ticket_Contact_Name}
                    </div>
                    <div className="portal_ticket_data_item">
                      {ticket.Ticket_Contact_Company}
                    </div>
                    <div className="portal_ticket_data_item">
                      {ticket.Ticket_Status}
                    </div>
                    <div className="portal_ticket_data_item">
                      {userLookup(ticket.Ticket_Owner)}
                    </div>
                    <div className="portal_ticket_data_item">
                      {FormatDate(ticket.createdAt)}
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
        ;
      </div>
    </>
  );
};

export default TicketsTable;
