import React, { useContext, useEffect, useState } from "react";
import PortalHeader from "../components/PortalHeader";
import Tickets from "../content/portal/Tickets";
import SubmitTicket from "../content/public/SubmitTicket";
import usersContext from "../context/users/usersContext";
import EcConfigs from "../content/portal/EcConfigs";

import "../css/portal.css";

const Portal = () => {
  const { GetUsers, users } = useContext(usersContext);
  const [content, setContent] = useState("tickets");

  useEffect(() => {
    GetUsers();

    // eslint-disable-next-line
  }, []);

  switch (content) {
    default:
    case "tickets":
      return (
        <>
          <PortalHeader setContent={setContent} />
          {users.length > 0 && <Tickets setContent={setContent} />}
        </>
      );
    case "EcConfigs":
      return (
        <>
          <PortalHeader setContent={setContent} />
          {<EcConfigs />}
        </>
      );
    case "newTicket":
      return (
        <>
          <PortalHeader setContent={setContent} />
          {<SubmitTicket referringPage={"portal"} setContent={setContent} />}
        </>
      );
  }
};

export default Portal;
