import React from "react";

const TicketResolutionCard = ({ ticket, editMode, handleEdits }) => {
  return (
    <div className="portal_ticket_card middle_card">
      <div className="portal_ticket_details_label">RESOLUTION:</div>
      <div className="portal_ticket_field_value_container">
        <textarea
          rows="8"
          name="Ticket_Resolution"
          id="Ticket_Resolution"
          className={
            editMode
              ? "portal_ticket_field_value_editable"
              : "portal_ticket_field_value"
          }
          defaultValue={ticket.Ticket_Resolution}
          readOnly={!editMode}
          onChange={handleEdits}
        />
      </div>
    </div>
  );
};

export default TicketResolutionCard;
