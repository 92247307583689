import React, { useContext } from "react";
import alertContext from "../context/alert/alertContext";

import "../css/alert.css";

const Alert = () => {
  const { showAlert, message, buttonText, alertFunction, closeAlert } =
    useContext(alertContext);

  function confirmAlert() {
    alertFunction(true);
    closeAlert(true);
  }
  function cancelAlert() {
    alertFunction(false);
    closeAlert(false);
  }
  if (!showAlert) return;
  return (
    <div className="alert_body">
      <div className="alert_container">
        <div className="alert_card">
          <div className="alert_icon_container">
            <div className="error_icon">CONFIRMATION</div>
          </div>
          <div className="alert_message_container">
            <div className="alert_message">{message}</div>
          </div>
          <div className="alert_button_container">
            <div
              onClick={cancelAlert}
              className="alert_dismiss_button w-button"
            >
              CANCEL
            </div>
            <div
              onClick={confirmAlert}
              className="alert_confirm_button w-button"
            >
              {buttonText}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
