import React, { useContext, useState, useEffect } from "react";
import AlertContext from "../../../context/alert/alertContext";
import { DeleteTicket } from "../../../functions/Ticket";

const TicketButtons = ({
  setPage,
  ticket,
  getTickets,
  setShowCloseForm,
  editMode,
  setEditMode,
  saveEdits,
  acknowledgeTicket,
  setShowOwnerModal,
}) => {
  const { setAlert } = useContext(AlertContext);
  const [deleteTicket, setDeleteTicket] = useState(false);

  const deleteATicket = () => {
    setAlert("Delete Ticket", "Confirmed", setDeleteTicket);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const cancelEdits = () => {
    setEditMode(!editMode);
    document.getElementById("Ticket_Contact_Company").value =
      ticket.Ticket_Contact_Company;
    document.getElementById("Ticket_Contact_Name").value =
      ticket.Ticket_Contact_Name;
    document.getElementById("Ticket_Contact_Phone").value =
      ticket.Ticket_Contact_Phone;
    document.getElementById("Ticket_Contact_Email").value =
      ticket.Ticket_Contact_Email;
    document.getElementById("Ticket_Status").value = ticket.Ticket_Status;
    document.getElementById("Ticket_Topic").value = ticket.Ticket_Topic;
    document.getElementById("Ticket_Owner").value = ticket.Ticket_Owner;
    document.getElementById("Ticket_Preferred_Contact_Method").value =
      ticket.Ticket_Preferred_Contact_Method;
    document.getElementById("Ticket_Issue_Details").value =
      ticket.Ticket_Issue_Details;
    document.getElementById("Ticket_Resolution").value =
      ticket.Ticket_Resolution;
  };

  const returnToTickets = () => {
    getTickets();
    setPage("tickets");
  };

  useEffect(() => {
    if (deleteTicket) {
      DeleteTicket(ticket._id);
      setPage("tickets");
      getTickets();
    }
  }, [deleteTicket, ticket, setPage, getTickets]);

  return (
    <div className="ticket_button_container">
      {!editMode && (
        <>
          <div className="ticket_button" onClick={returnToTickets}>
            BACK
          </div>
          <div className="ticket_button" onClick={deleteATicket}>
            DELETE
          </div>
          <div className="ticket_button" onClick={toggleEditMode}>
            EDIT
          </div>
        </>
      )}
      {editMode && (
        <div className="ticket_button" onClick={cancelEdits}>
          CANCEL
        </div>
      )}
      {!editMode ? (
        <>
          <div className="ticket_button" onClick={acknowledgeTicket}>
            ACKNOWLEDGE
          </div>
          <div className="ticket_button" onClick={setShowOwnerModal}>
            ASSIGN
          </div>
          <div className="ticket_button" onClick={() => setShowCloseForm(true)}>
            CLOSE
          </div>
        </>
      ) : (
        <div className="ticket_button" onClick={saveEdits}>
          SAVE
        </div>
      )}
    </div>
  );
};

export default TicketButtons;
