import axios from "axios";

export const FetchEcConfig = async (filter) => {
  let data = {};

  try {
    await axios.post("/api/ecconfig/query", filter).then((res) => {
      data = res.data;
    });
  } catch (err) {
    console.log(err);
    data = "error";
  }
  return data;
};
export const DeleteConfig = async (id) => {
  let data = {};

  try {
    await axios.delete(`/api/ecconfig/${id}`).then((res) => {
      data = res.data;
    });
  } catch (err) {
    console.log(err);
    data = "error";
  }
  return data;
};
