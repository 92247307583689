import React from "react";

const ConfigTopCard = ({ config, editMode, handleEdits }) => {
  return (
    <div className="portal_config_card">
      <div className="portal_config_detail_top_container">
        <div className="portal_config_detail_left_container">
          <div className="portal_config_detail_row">
            <div className="portal_config_field_name">ACCOUNT #</div>
            <div className="portal_config_field_value">{config.Acct}</div>
          </div>
          <div className="portal_config_detail_row">
            <div className="portal_config_field_name">COMPANY:</div>
            <div className="portal_config_field_value_container">
              <input
                type="text"
                maxLength="256"
                name="CompanyName"
                id="CompanyName"
                className={
                  editMode
                    ? "portal_config_field_value_editable"
                    : "portal_config_field_value"
                }
                defaultValue={config.CompanyName}
                readOnly={!editMode}
                onChange={handleEdits}
              />
            </div>
          </div>
          <div className="portal_config_detail_row">
            <div className="portal_config_field_name">LOCATION:</div>
            <div className="portal_config_field_value_container">
              <input
                type="text"
                maxLength="256"
                name="Location_Name"
                id="Location_Name"
                className={
                  editMode
                    ? "portal_config_field_value_editable"
                    : "portal_config_field_value"
                }
                defaultValue={config.Location_Name}
                readOnly={!editMode}
                onChange={handleEdits}
              />
            </div>
          </div>
        </div>
        <div className="portal_config_detail_right_container">
          <div className="portal_config_detail_row">
            <div className="portal_config_field_name">ACTIVE:</div>
            <div className="portal_config_field_value_container">
              <select
                id="active"
                name="active"
                onChange={handleEdits}
                defaultValue={config.active}
                className={
                  editMode
                    ? "portal_config_field_value_editable"
                    : "portal_config_field_value"
                }
                disabled={!editMode}
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>
          </div>
          <div className="portal_config_detail_row">
            <div className="portal_config_field_name">CONFIG ID:</div>
            <div className="portal_config_field_value_container">
              <input
                type="text"
                maxLength="256"
                name="_id"
                id="_id"
                className={
                  editMode
                    ? "portal_config_field_value_editable"
                    : "portal_config_field_value"
                }
                defaultValue={config._id}
                readOnly
                onChange={handleEdits}
              />
            </div>
          </div>
          <div className="portal_config_detail_row">
            <div className="portal_config_field_name">SITE ADDRESS:</div>
            <div className="portal_config_field_value_container">
              <input
                type="text"
                maxLength="256"
                name="SiteAddress"
                id="SiteAddress"
                className={
                  editMode
                    ? "portal_config_field_value_editable"
                    : "portal_config_field_value"
                }
                defaultValue={config.SiteAddress}
                readOnly={!editMode}
                onChange={handleEdits}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigTopCard;
