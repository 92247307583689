import axios from "axios";

export const FetchTicket = async (filter) => {
  let data = {};

  try {
    await axios.post("/api/tickets/query", filter).then((res) => {
      data = res.data;
    });
  } catch (err) {
    console.log(err);
    data = "error";
  }
  return data;
};
export const DeleteTicket = async (id) => {
  let data = {};

  try {
    await axios.delete(`/api/tickets/${id}`).then((res) => {
      data = res.data;
    });
  } catch (err) {
    console.log(err);
    data = "error";
  }
  return data;
};
