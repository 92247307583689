import React from "react";

const TicketDetailsCard = ({ ticket, editMode, handleEdits }) => {
  return (
    <div className="portal_ticket_card middle_card">
      <div className="portal_ticket_details_label">DETAILS:</div>
      <div className="portal_ticket_field_value_container">
        <textarea
          rows="8"
          name="Ticket_Issue_Details"
          id="Ticket_Issue_Details"
          className={
            editMode
              ? "portal_ticket_field_value_editable"
              : "portal_ticket_field_value"
          }
          defaultValue={ticket.Ticket_Issue_Details}
          readOnly={!editMode}
          onChange={handleEdits}
        />
      </div>
    </div>
  );
};

export default TicketDetailsCard;
