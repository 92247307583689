import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";

const TicketNoteForm = ({ ticket, setShowNoteForm, setNotes, notes }) => {
  const [newNote, setNewNote] = useState({
    Ticket_Note: "",
    Ticket_Number: ticket._id,
    Note_Author: 2,
  });

  const [errors, setErrors] = useState({
    Ticket_Note: false,
  });

  const validateInputs = () => {
    let Ticket_Note = false;
    let valid = true;

    if (newNote.Ticket_Note === "") {
      Ticket_Note = true;
      valid = false;
    }

    setErrors({
      Ticket_Resolution: Ticket_Note,
    });

    return valid;
  };

  async function addNoteToTicket() {
    if (validateInputs()) {
      try {
        const res = await axios.post(`/api/ticketNotes`, newNote);
        if (res.status === 200) {
          let newNotesList = [];
          notes.map((note) => newNotesList.push(note));
          newNotesList.push(res.data);
          setNotes(newNotesList);
          setShowNoteForm(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  function cancelNote() {
    setShowNoteForm(false);
  }

  const handleInput = (event, editor) => {
    const data = editor.getData();
    setNewNote({ ...newNote, Ticket_Note: data });
  };

  return (
    <div className="note_container">
      <div className="note_card">
        <div className="note_message_container">
          <div className="note_form_row">
            <label htmlFor="field" className="note_field_label">
              NEW NOTE
            </label>
            {errors.Ticket_Resolution && (
              <label
                htmlFor="Ticket_Issue_Details"
                className="form_error_message"
              >
                Enter Notes for the ticket
              </label>
            )}

            <CKEditor editor={ClassicEditor} onChange={handleInput} />
          </div>
        </div>
        <div className="note_button_container">
          <div onClick={cancelNote} className="note_dismiss_button w-button">
            CANCEL
          </div>
          <div
            onClick={addNoteToTicket}
            className="note_confirm_button w-button"
          >
            SAVE
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketNoteForm;
