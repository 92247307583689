import React, { useContext } from "react";
import usersContext from "../../../context/users/usersContext";
import userLookup from "../../../functions/UserLookup";

const TicketMiddleCard = ({ ticket, editMode, handleEdits }) => {
  const { users } = useContext(usersContext);

  return (
    <div className="portal_ticket_card middle_card">
      <div className="portal_ticket_detail_top_container">
        <div className="portal_ticket_detail_left_container">
          <div className="portal_ticket_detail_row">
            <div className="portal_ticket_field_name">Acknowledged:</div>
            <div className="portal_ticket_field_value">
              {ticket.Ticket_Acknowledged === true ? "Yes" : "No"}
            </div>
          </div>
          <div className="portal_ticket_detail_row">
            <div className="portal_ticket_field_name">OWNER:</div>
            <div className="portal_ticket_field_value_container">
              <select
                id="Ticket_Owner2"
                name="Ticket_Owner"
                onChange={handleEdits}
                defaultValue={ticket.Ticket_Owner}
                className={
                  editMode
                    ? "portal_ticket_field_value_editable"
                    : "portal_ticket_field_value"
                }
                disabled={!editMode}
              >
                {users.map((user) => (
                  <option key={user._id} value={user._id}>
                    {userLookup(user._id)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="portal_ticket_detail_right_container">
          <div className="portal_ticket_detail_row">
            <div className="portal_ticket_field_name">CONTACT METHOD:</div>
            <div className="portal_ticket_field_value_container">
              <select
                id="Ticket_Preferred_Contact_Method"
                name="Ticket_Preferred_Contact_Method"
                onChange={handleEdits}
                defaultValue={ticket.Ticket_Preferred_Contact_Method}
                className={
                  editMode
                    ? "portal_ticket_field_value_editable"
                    : "portal_ticket_field_value"
                }
                disabled={!editMode}
              >
                <option value="Phone">Phone</option>
                <option value="Email">Email</option>
                <option value="Text">Text</option>
              </select>
            </div>
          </div>
          <div className="portal_ticket_detail_row">
            <div className="portal_ticket_field_name">SOURCE:</div>
            <div className="portal_ticket_field_value">
              {ticket.Ticket_Source}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketMiddleCard;
