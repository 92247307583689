import axios from "axios";

export const FetchTickets = async () => {
  let data = {};

  try {
    await axios.get("/api/tickets/all").then((res) => {
      data = res.data;
    });
  } catch (err) {
    console.log(err);
    data = "error";
  }
  return data;
};

export const FilterTickets = async (filter) => {
  let data = {};

  try {
    await axios.post("/api/tickets/query", filter).then((res) => {
      data = res.data;
    });
  } catch (err) {
    console.log(err);
    data = "error";
  }
  return data;
};
