import React, { useState, useContext } from "react";
import TicketButtons from "./TicketButtons";
import TicketTopCard from "./TicketTopCard";
import TicketMiddleCard from "./TicketMiddleCard";
import TicketDetailsCard from "./TicketDetailsCard";
import TicketNotesSection from "./TicketNotesSection";
import TicketEmailsSection from "./TicketEmailsSection";
import TicketResolutionCard from "./TicketResolutionCard";
import TicketCloseForm from "./TicketCloseForm";
import axios from "axios";
import usersContext from "../../../context/users/usersContext";
import UserLookup from "../../../functions/UserLookup";

import "../../../css/ticketDetail.css";
import "../../../css/ownership_modal.css";

const TicketDetail = ({ ticket, setTicket, setPage, getTickets }) => {
  const [showCloseForm, setShowCloseForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [ownershipUpdate, setOwnershipUpdate] = useState(null);
  const [showOwnerModal, setShowOwnerModal] = useState(false);
  const [ticketEdits, setTicketEdits] = useState({});
  const { users } = useContext(usersContext);

  const saveEdits = async () => {
    try {
      const res = await axios.put(`/api/tickets/${ticket._id}`, ticketEdits);
      if (res.status === 200) {
        setEditMode(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cancelAssignment = () => {
    setShowOwnerModal();
    document.getElementById("Ticket_Owner").value = ticket.Ticket_Owner;
  };

  const confirmAssignment = async (id) => {
    const updates = {
      Ticket_Owner: ownershipUpdate,
    };
    try {
      const res = await axios.put(`/api/tickets/${ticket._id}`, updates);
      if (res.status === 200) {
        setShowOwnerModal(false);
        setTicket({ ...ticket, Ticket_Owner: ownershipUpdate });
        document.getElementById("Ticket_Owner2").value = ownershipUpdate;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdits = (e) => {
    setTicketEdits({ ...ticketEdits, [e.target.name]: e.target.value });
  };

  const handleOwnershipUpdate = (e) => {
    setOwnershipUpdate(e.target.value);
  };

  const Ownership = () => {
    return (
      <div className="ownership_modal_body">
        <div className="ownership_modal_container">
          <div className="ownership_modal_card">
            <div className="ownership_modal_icon_container">
              <div className="error_icon">ASSIGN TICKET</div>
            </div>
            <div className="ownership_modal_message_container">
              <div className="ownership_modal_message">
                <select
                  id="Ticket_Owner"
                  name="Ticket_Owner"
                  defaultValue={ticket.Ticket_Owner}
                  className="ownership_modal_field_value_editable"
                  onChange={handleOwnershipUpdate}
                >
                  {users.map((user) => (
                    <option
                      key={user._id}
                      value={user._id}
                      className="owner_select"
                    >
                      {UserLookup(user._id)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="ownership_modal_button_container">
              <div
                onClick={cancelAssignment}
                className="ownership_modal_dismiss_button w-button"
              >
                CANCEL
              </div>
              <div
                onClick={confirmAssignment}
                className="ownership_modal_confirm_button w-button"
              >
                ASSIGN
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const acknowledgeTicket = async () => {
    if (ticket.Ticket_Acknowledged) return;
    const acknowledgeTicketDetails = { Ticket_Acknowledged: true };
    try {
      const res = await axios.put(
        `/api/tickets/${ticket._id}`,
        acknowledgeTicketDetails
      );
      if (res.status === 200) {
        setTicket({ ...ticket, Ticket_Acknowledged: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="portal_body">
      {showCloseForm && (
        <TicketCloseForm ticket={ticket} setShowCloseForm={setShowCloseForm} />
      )}
      {showOwnerModal && <Ownership />}

      <TicketButtons
        setPage={setPage}
        ticket={ticket}
        getTickets={getTickets}
        setShowCloseForm={setShowCloseForm}
        editMode={editMode}
        setEditMode={setEditMode}
        saveEdits={saveEdits}
        acknowledgeTicket={acknowledgeTicket}
        setShowOwnerModal={setShowOwnerModal}
      />
      <TicketTopCard
        ticket={ticket}
        editMode={editMode}
        handleEdits={handleEdits}
      />
      <TicketMiddleCard
        ticket={ticket}
        editMode={editMode}
        handleEdits={handleEdits}
      />
      <TicketDetailsCard
        ticket={ticket}
        editMode={editMode}
        handleEdits={handleEdits}
      />
      {!ticket.Ticket_Open && <TicketResolutionCard ticket={ticket} />}
      <TicketNotesSection
        ticket={ticket}
        editMode={editMode}
        handleEdits={handleEdits}
      />
      <TicketEmailsSection ticket={ticket} />
    </div>
  );
};

export default TicketDetail;
