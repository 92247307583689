import React, { useContext } from "react";
import AuthContext from "../context/auth/authContext";
import Logo from "../images/abt-logo---150x90px.png";

import "../css/portalHeader.css";

const PortalHeader = () => {
  const { logoutUser } = useContext(AuthContext);
  return (
    <header className="header">
      <div className="menu_container">
        <div className="logout_button" onClick={logoutUser}>
          Sign Out
        </div>
        <div className="logo_container">
          <img src={Logo} alt="AB&T Telecom" className="logo" />
        </div>
      </div>
    </header>
  );
};

export default PortalHeader;
