import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

import "../../css/submit.css";
import "../../css/webflow.css";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const SubmitTicket = ({ referringPage, setContent }) => {
  const [ticketData, setTicketData] = useState({
    Ticket_Contact_Name: "",
    Ticket_Contact_Phone: "",
    Ticket_Contact_Email: "",
    Ticket_Contact_Company: "",
    Ticket_Issue_Details: "",
    Ticket_Preferred_Contact_Method: "",
    Ticket_Topic: "",
    Ticket_Source: "Web",
  });
  const [errors, setErrors] = useState({
    Name: false,
    Phone: false,
    Email: false,
    Company: false,
    Details: false,
  });

  const navigate = useNavigate();

  const validateInputs = () => {
    let name = false;
    let phone = false;
    let email = false;
    let company = false;
    let details = false;
    let valid = true;

    if (ticketData.Ticket_Contact_Name === "") {
      name = true;
      valid = false;
    }

    if (ticketData.Ticket_Contact_Phone === "") {
      phone = true;
      valid = false;
    }
    if (ticketData.Ticket_Contact_Email === "") {
      email = true;
      valid = false;
    }
    if (ticketData.Ticket_Contact_Company === "") {
      company = true;
      valid = false;
    }
    if (ticketData.Ticket_Issue_Details === "") {
      details = true;
      valid = false;
    }

    setErrors({
      Name: name,
      Phone: phone,
      Email: email,
      Company: company,
      Details: details,
    });

    return valid;
  };

  const queryParameters = new URLSearchParams(window.location.search);
  const source = queryParameters.get("source");

  useEffect(() => {
    if (source === "PhoneSupportGroup") {
      setTicketData({ ...ticketData, Ticket_Source: "Answering Service" });
    }
    if (source === "internal") {
      setTicketData({ ...ticketData, Ticket_Source: "internal" });
    }

    // eslint-disable-next-line
  }, []);

  const handleInput = (e) => {
    if (e.target.name === "Ticket_Contact_Name" && e.target.value.length > 0) {
      setErrors({ ...errors, Name: false });
    }
    if (e.target.name === "Ticket_Contact_Phone" && e.target.value.length > 0) {
      setErrors({ ...errors, Phone: false });
    }
    if (e.target.name === "Ticket_Contact_Email" && e.target.value.length > 0) {
      setErrors({ ...errors, Email: false });
    }
    if (
      e.target.name === "Ticket_Contact_Company" &&
      e.target.value.length > 0
    ) {
      setErrors({ ...errors, Company: false });
    }
    if (e.target.name === "Ticket_Issue_Details" && e.target.value.length > 0) {
      setErrors({ ...errors, Details: false });
    }
    setTicketData({ ...ticketData, [e.target.name]: e.target.value });
  };

  const submitTicket = async () => {
    if (validateInputs()) {
      window.scroll(0, 0);
      try {
        await Axios.post("/api/tickets", ticketData, config).then((res) => {
          const id = res.data._id;
          if (res.status === 200) {
            if (referringPage === "public") {
              navigate(`/success?ticket=${id}`);
            }
            if (referringPage === "portal") {
              setContent("tickets");
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="body public">
      <section className="section-hero">
        <div className="hero_title_container">
          <div className="hero-header">
            <div className="hero-title">
              <h1 className="heading">Support Ticket Request</h1>
            </div>
          </div>
        </div>
        <div className="hero-container">
          <div className="form-block w-form">
            <div
              id="email-form"
              name="email-form"
              method="get"
              className="form"
            >
              <div className="hero_form_row">
                <label htmlFor="Ticket_Contact_Name" className="field-label">
                  Name
                </label>
                {errors.Name && (
                  <label
                    htmlFor="Ticket_Contact_Name"
                    className="form_error_message"
                  >
                    Name is Required
                  </label>
                )}
                <input
                  type="text"
                  className="hero_form_text_field w-input"
                  maxLength="256"
                  name="Ticket_Contact_Name"
                  id="Ticket_Contact_Name"
                  onChange={handleInput}
                />
              </div>
              <div className="hero_form_row">
                <label htmlFor="Ticket_Contact_Company" className="field-label">
                  Company
                </label>
                {errors.Company && (
                  <label
                    htmlFor="Ticket_Contact_Company"
                    className="form_error_message"
                  >
                    Company is Required
                  </label>
                )}
                <input
                  type="text"
                  className="hero_form_text_field w-input"
                  maxLength="256"
                  name="Ticket_Contact_Company"
                  id="Ticket_Contact_Company"
                  onChange={handleInput}
                />
              </div>
              <div className="hero_form_row">
                <label htmlFor="Ticket_Contact_Phone" className="field-label">
                  Phone
                </label>
                {errors.Phone && (
                  <label
                    htmlFor="Ticket_Contact_Phone"
                    className="form_error_message"
                  >
                    Phone is Required
                  </label>
                )}
                <input
                  type="tel"
                  className="hero_form_text_field w-input"
                  maxLength="256"
                  name="Ticket_Contact_Phone"
                  id="Ticket_Contact_Phone"
                  onChange={handleInput}
                />
              </div>
              <div className="hero_form_row">
                <label htmlFor="Ticket_Contact_Email" className="field-label">
                  Email
                </label>
                {errors.Email && (
                  <label
                    htmlFor="Ticket_Contact_Email"
                    className="form_error_message"
                  >
                    Email is Required
                  </label>
                )}
                <input
                  type="email"
                  className="hero_form_text_field  w-input"
                  maxLength="256"
                  name="Ticket_Contact_Email"
                  id="Ticket_Contact_Email"
                  onChange={handleInput}
                />
              </div>
              <div className="hero_form_row select">
                <label
                  htmlFor="Ticket_Preferred_Contact_Method"
                  className="field-label"
                >
                  Preferred Contact Method
                </label>
                <select
                  id="Ticket_Preferred_Contact_Method"
                  name="Ticket_Preferred_Contact_Method"
                  onChange={handleInput}
                  className="select-field w-select"
                >
                  <option value="">Select one...</option>
                  <option value="Phone">Phone</option>
                  <option value="Email">Email</option>
                  <option value="Text (SMS)">Text (SMS)</option>
                </select>
              </div>
              <div className="hero_form_row select">
                <label htmlFor="Ticket_Topic" className="field-label">
                  Which service is your issue related to
                </label>
                <select
                  id="Ticket_Topic"
                  name="Ticket_Topic"
                  onChange={handleInput}
                  className="select-field w-select"
                >
                  <option value="">Select one...</option>
                  <option value="Voice">Voice</option>
                  <option value="Internet">Internet</option>
                  <option value="Cellular Internet">Cellular Internet</option>
                  <option value="SD WAN">SD WAN</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              {(source === "internal") | (referringPage === "portal") ? (
                <div className="hero_form_row select">
                  <label htmlFor="Ticket_Source" className="field-label">
                    What is the source of this Ticket?
                  </label>
                  <select
                    id="Ticket_Source"
                    name="Ticket_Source"
                    className="select-field w-select"
                    onChange={handleInput}
                  >
                    <option value="">Select one...</option>
                    <option value="Internal Phone">Phone</option>
                    <option value="Internal Email">Email</option>
                    <option value="Internal Text">Text</option>
                  </select>
                </div>
              ) : null}
              <div className="hero_form_row">
                <label htmlFor="field" className="field-label">
                  Please provide details of the issue that you are experiencing.
                </label>
                {errors.Details && (
                  <label
                    htmlFor="Ticket_Issue_Details"
                    className="form_error_message"
                  >
                    Details is Required
                  </label>
                )}
                <textarea
                  placeholder=""
                  maxLength="5000"
                  id="Ticket_Issue_Details"
                  name="Ticket_Issue_Details"
                  className="textarea w-input"
                  onChange={handleInput}
                  rows={8}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="button_container">
          <div className="submit-button" onClick={submitTicket}>
            Submit
          </div>
        </div>
      </section>
    </div>
  );
};

export default SubmitTicket;
