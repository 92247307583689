import React, { useState } from "react";
import axios from "axios";

import "../../../css/ticketNote.css";

const TicketCloseForm = ({ ticket, setShowCloseForm }) => {
  const [updates, setUpdates] = useState({
    Ticket_Acknowledged: true,
    Ticket_Closed_Date: Date.now(),
    Ticket_Open: false,
    Ticket_Resolution: "",
    Ticket_Status: "Closed",
  });

  const [errors, setErrors] = useState({
    Ticket_Resolution: false,
  });

  const validateInputs = () => {
    let Ticket_Resolution = false;
    let valid = true;

    if (updates.Ticket_Resolution === "") {
      Ticket_Resolution = true;
      valid = false;
    }

    setErrors({
      Ticket_Resolution: Ticket_Resolution,
    });

    return valid;
  };

  async function confirmClose() {
    if (validateInputs()) {
      console.log(updates);
      try {
        const res = await axios.put(`/api/tickets/${ticket._id}`, updates);
        if (res.status === 200) {
          setShowCloseForm(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  function cancelCloseTicket() {
    setShowCloseForm(false);
  }

  const handleInput = (e) => {
    setUpdates({ ...updates, Ticket_Resolution: e.target.value });
  };
  return (
    <div className="modal_body">
      <div className="modal_container">
        <div className="modal_card">
          <div className="modal_icon_container">
            <div className="error_icon">CLOSE TICKET</div>
          </div>
          <div className="modal_message_container">
            <div className="modal_form_row">
              <label htmlFor="field" className="modal_field_label">
                Please provide ticket resolution notes for future reference
              </label>
              {errors.Ticket_Resolution && (
                <label
                  htmlFor="Ticket_Issue_Details"
                  className="form_error_message"
                >
                  Please enter resolution notes
                </label>
              )}
              <textarea
                placeholder=""
                maxLength="5000"
                id="Ticket_Issue_Details"
                name="Ticket_Issue_Details"
                className="textarea w-input"
                onChange={handleInput}
                rows={10}
              ></textarea>
            </div>
          </div>
          <div className="modal_button_container">
            <div
              onClick={cancelCloseTicket}
              className="modal_dismiss_button w-button"
            >
              CANCEL
            </div>
            <div
              onClick={confirmClose}
              className="modal_confirm_button w-button"
            >
              CLOSE TICKET
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketCloseForm;
