import React from "react";
import Logo from "../images/abt-logo---150x90px.png";

import "../css/header.css";

const Header = () => {
  return (
    <header className="header">
      <div className="menu_container">
        <div className="logo_container">
          <img src={Logo} alt="AB&T Telecom" className="logo" />
        </div>
      </div>
    </header>
  );
};

export default Header;
