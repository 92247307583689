import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import Portal from "../pages/Portal";
import AuthContext from "../context/auth/authContext";
// import SessionTimeout from "../utils/SessionTimeout";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, loadUser, loading } = useContext(AuthContext);

  useEffect(() => {
    if (localStorage.auth_token) {
      loadUser(localStorage.auth_token);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {!isAuthenticated && loading ? (
        <Navigate to="/login" />
      ) : (
        <>
          <Portal />
          {/* <SessionTimeout /> */}
        </>
      )}
    </div>
  );
};

export default PrivateRoute;
