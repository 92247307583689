import React, { useContext, useState, useEffect } from "react";
import AlertContext from "../../../context/alert/alertContext";
import { DeleteConfig } from "../../../functions/EcConfig";

const ConfigButtons = ({
  setPage,
  config,
  getConfigs,
  editMode,
  setEditMode,
  saveEdits,
}) => {
  const { setAlert } = useContext(AlertContext);
  const [deleteConfig, setDeleteTicket] = useState(false);

  const deleteAConfig = () => {
    setAlert("Delete Ticket", "Confirmed", setDeleteTicket);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const cancelEdits = () => {
    setEditMode(!editMode);
    document.getElementById("CompanyName").value =
      config.Ticket_Contact_Company;
    document.getElementById("APN").value = config.APN;
    document.getElementById("Acct").value = config.Acct;
    document.getElementById("Gateway").value = config.Gateway;
    document.getElementById("Location_Name").value = config.Location_Name;
    document.getElementById("MobileID").value = config.MobileID;
    document.getElementById("Provider").value = config.Provider;
    document.getElementById("RatePlan").value = config.RatePlan;
    document.getElementById("SIM").value = config.SIM;
    document.getElementById("StaticIP").value = config.StaticIP;
    document.getElementById("Subnet").value = config.Subnet;
    document.getElementById("active").value = config.active;
  };

  const returnToConfigs = () => {
    getConfigs();
    setPage("configs");
  };

  useEffect(() => {
    if (deleteConfig) {
      DeleteConfig(config._id);
      setPage("configs");
      getConfigs();
    }
  }, [deleteConfig, config, setPage, getConfigs]);

  return (
    <div className="ticket_button_container">
      {!editMode && (
        <>
          <div className="ticket_button" onClick={returnToConfigs}>
            BACK
          </div>
          <div className="ticket_button" onClick={deleteAConfig}>
            DELETE
          </div>
          <div className="ticket_button" onClick={toggleEditMode}>
            EDIT
          </div>
        </>
      )}
      {editMode && (
        <>
          <div className="ticket_button" onClick={cancelEdits}>
            CANCEL
          </div>
          <div className="ticket_button" onClick={saveEdits}>
            SAVE
          </div>
        </>
      )}
    </div>
  );
};

export default ConfigButtons;
