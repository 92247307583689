import React, { useState, useEffect } from "react";
import { FilterEcConfigs } from "../../functions/EcConfigs";
import { FetchEcConfig } from "../../functions/EcConfig";
import EcConfigTable from "./ecConfigTable/EcConfigTable";
import EcConfigDetail from "./ecConfigDetail/EcConfigDetail";

const EcConfigs = () => {
  const [page, setPage] = useState("configs");
  const [configs, setConfigs] = useState(null);
  const [filter, setFilter] = useState({
    active: true,
  });
  const [config, setConfig] = useState(null);

  const getConfigs = async () => {
    await FilterEcConfigs(filter).then((response) => {
      setConfigs(response);
    });
  };

  const getAConfig = async (e) => {
    window.scroll(0, 0);
    await FetchEcConfig({ _id: e })
      .then((response) => {
        setConfig(response[0]);
      })
      .then(() => {
        setPage("configDetail");
      });
  };

  useEffect(() => {
    getConfigs();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getConfigs();
    //eslint-disable-next-line
  }, [filter]);

  switch (page) {
    default:
    case "configs":
      return (
        <EcConfigTable
          configs={configs}
          getAconfig={getAConfig}
          filter={filter}
          setFilter={setFilter}
        />
      );
    case "configDetail":
      return (
        <EcConfigDetail
          config={config}
          setconfig={setConfig}
          setPage={setPage}
          getConfigs={getConfigs}
        />
      );
  }
};

export default EcConfigs;
