import React, { useReducer } from "react";
import AlertContext from "./alertContext";
import alertReducer from "./alertReducer";
import { SET_ALERT, REMOVE_ALERT } from "../types";

const AlertState = (props) => {
  const initialState = {
    showAlert: false,
    message: "",
    buttonText: "",
    alertFunction: () => {},
  };

  const [state, dispatch] = useReducer(alertReducer, initialState);

  // Set Alert
  const setAlert = (message, buttonText, alertFunction) => {
    const alertData = {
      showAlert: true,
      message: message,
      buttonText: buttonText,
      alertFunction: alertFunction,
    };

    dispatch({
      type: SET_ALERT,
      payload: alertData,
    });
  };

  const closeAlert = (confirmation) => {
    dispatch({
      type: REMOVE_ALERT,
      payload: confirmation,
    });
  };
  return (
    <AlertContext.Provider
      value={{
        showAlert: state.showAlert,
        message: state.message,
        buttonText: state.buttonText,
        alertFunction: state.alertFunction,
        setAlert,
        closeAlert,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertState;
