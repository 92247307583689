import React, { useState, useEffect } from "react";
import { FilterTickets } from "../../functions/Tickets";
import { FetchTicket } from "../../functions/Ticket";
import TicketsTable from "./ticketsTable/TicketsTable";
import TicketDetail from "./ticketDetail/TicketDetail";

const Tickets = ({ setContent }) => {
  const [page, setPage] = useState("tickets");
  const [tickets, setTickets] = useState(null);
  const [filter, setFilter] = useState({
    Ticket_Open: true,
  });
  const [ticket, setTicket] = useState(null);

  const getTickets = async () => {
    await FilterTickets(filter).then((response) => {
      setTickets(response);
    });
  };

  const getATicket = async (e) => {
    await FetchTicket({ _id: e })
      .then((response) => {
        setTicket(response[0]);
      })
      .then(() => {
        setPage("ticketDetail");
      });
  };

  useEffect(() => {
    getTickets();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getTickets();
    //eslint-disable-next-line
  }, [filter]);

  switch (page) {
    default:
    case "tickets":
      return (
        <TicketsTable
          tickets={tickets}
          getATicket={getATicket}
          filter={filter}
          setFilter={setFilter}
          setContent={setContent}
        />
      );
    case "ticketDetail":
      return (
        <TicketDetail
          ticket={ticket}
          setTicket={setTicket}
          setPage={setPage}
          getTickets={getTickets}
        />
      );
  }
};

export default Tickets;
