import React, { useState, useEffect, useContext } from "react";
import * as DOMPurify from "dompurify";
import UserContext from "../../../context/users/usersContext";
import { FormatDateTime } from "../../../functions/ddmmyyyy";
import {
  FilterTicketNotes,
  DeleteTicketNote,
} from "../../../functions/FetchTicketNotes";
import TicketNoteForm from "./TicketNoteForm";
import parse from "html-react-parser";

const TicketNotesSection = ({ ticket }) => {
  const [notes, setNotes] = useState(null);
  const [showNoteForm, setShowNoteForm] = useState(false);
  const { users } = useContext(UserContext);

  const renderHTMLBody = (email) => {
    let clean = DOMPurify.sanitize(email);

    return clean;
  };

  const getTicketNotes = async () => {
    await FilterTicketNotes({ Ticket_Number: ticket._id }).then((response) => {
      setNotes(response);
    });
  };

  const NameLookup = (id) => {
    const userData = users.filter((user) => user._id === id);
    const name = userData[0].FirstName + " " + userData[0].LastName;
    return name;
  };

  const deleteNote = async (id) => {
    const res = await DeleteTicketNote(id);
    if (res === "deleted") {
      const updatedNotes = notes.filter((note) => note._id !== id);
      setNotes(updatedNotes);
    } else {
      console.log(res);
    }
  };

  useEffect(() => {
    getTicketNotes();

    //eslint-disable-next-line
  }, []);

  return (
    <div className="portal_ticket_notes_container middle_card">
      <div className="portal_notes_button_container">
        {!showNoteForm && (
          <div
            className="portal_notes_button"
            onClick={() => setShowNoteForm(true)}
          >
            Add
          </div>
        )}
      </div>
      {showNoteForm && (
        <TicketNoteForm
          ticket={ticket}
          setShowNoteForm={setShowNoteForm}
          setNotes={setNotes}
          notes={notes}
        />
      )}
      {notes !== null ? (
        notes.map((note) => (
          <div key={note._id}>
            <div className="portal_note_detail_container">
              <div className="portal_note_detail_title_container">
                <div className="portal_note_detail">
                  {NameLookup(note.Note_Author)}
                </div>
                <div className="portal_note_detail">
                  @ {FormatDateTime(note.updatedAt)}
                </div>
              </div>
              <div className="portal_note_text">
                {parse(renderHTMLBody(note.Ticket_Note))}
              </div>
              <div className="portal_note_button_container">
                <div
                  className="portal_note_button"
                  onClick={() => deleteNote(note._id)}
                >
                  Delete
                </div>
                <div className="portal_note_button">Edit</div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div> NO Notes </div>
      )}
    </div>
  );
};

export default TicketNotesSection;
