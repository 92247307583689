import { Routes, Route, Outlet } from "react-router-dom";
import Home from "./pages/Home";
import Success from "./content/public/TicketSubmitted";
import AlertState from "./context/alert/AlertState";
import UserState from "./context/users/UserState";
import AuthState from "./context/auth/AuthState";
import Alert from "./components/Alert";
import PrivateRoute from "./routes/PrivateRoute";
import Login from "./content/portal/Login";
import setAuthToken from "./functions/setAuthToken";

if (localStorage.auth_token) {
  setAuthToken(localStorage.auth_token);
}

function App() {
  return (
    <div className="App">
      <AlertState>
        <AuthState>
          <UserState>
            <Alert />
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="portal" element={<PrivateRoute />} />
                <Route path="/login" element={<Login />}></Route>
                <Route path="success" element={<Success />} />
              </Route>
            </Routes>
          </UserState>
        </AuthState>
      </AlertState>
    </div>
  );
}

function Layout() {
  return (
    <>
      <Outlet />
    </>
  );
}

export default App;
