import axios from "axios";

export const FetchConfigs = async () => {
  let data = {};

  try {
    await axios.get("/api/ecconfig/all").then((res) => {
      data = res.data;
    });
  } catch (err) {
    console.log(err);
    data = "error";
  }
  return data;
};

export const FilterEcConfigs = async (filter) => {
  let data = {};

  try {
    await axios.post("/api/ecconfig/query", filter).then((res) => {
      data = res.data;
    });
  } catch (err) {
    console.log(err);
    data = "error";
  }
  return data;
};
