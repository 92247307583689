import React from "react";
import { FormatDateTime } from "../../../functions/ddmmyyyy";

const TicketTopCard = ({ ticket, editMode, handleEdits }) => {
  return (
    <div className="portal_ticket_card">
      <div className="portal_ticket_detail_top_container">
        <div className="portal_ticket_detail_left_container">
          <div className="portal_ticket_detail_row">
            <div className="portal_ticket_field_name">TICKET #</div>
            <div className="portal_ticket_field_value">{ticket._id}</div>
          </div>
          <div className="portal_ticket_detail_row">
            <div className="portal_ticket_field_name">COMPANY:</div>
            <div className="portal_ticket_field_value_container">
              <input
                type="text"
                maxLength="256"
                name="Ticket_Contact_Company"
                id="Ticket_Contact_Company"
                className={
                  editMode
                    ? "portal_ticket_field_value_editable"
                    : "portal_ticket_field_value"
                }
                defaultValue={ticket.Ticket_Contact_Company}
                readOnly={!editMode}
                onChange={handleEdits}
              />
            </div>
          </div>
          <div className="portal_ticket_detail_row">
            <div className="portal_ticket_field_name">NAME:</div>
            <div className="portal_ticket_field_value_container">
              <input
                type="text"
                maxLength="256"
                name="Ticket_Contact_Name"
                id="Ticket_Contact_Name"
                className={
                  editMode
                    ? "portal_ticket_field_value_editable"
                    : "portal_ticket_field_value"
                }
                defaultValue={ticket.Ticket_Contact_Name}
                readOnly={!editMode}
                onChange={handleEdits}
              />
            </div>
          </div>
          <div className="portal_ticket_detail_row">
            <div className="portal_ticket_field_name">PHONE:</div>
            <div className="portal_ticket_field_value_container">
              <input
                type="text"
                maxLength="256"
                name="Ticket_Contact_Phone"
                id="Ticket_Contact_Phone"
                className={
                  editMode
                    ? "portal_ticket_field_value_editable"
                    : "portal_ticket_field_value"
                }
                defaultValue={ticket.Ticket_Contact_Phone}
                readOnly={!editMode}
                onChange={handleEdits}
              />
            </div>
          </div>
          <div className="portal_ticket_detail_row">
            <div className="portal_ticket_field_name">EMAIL:</div>
            <div className="portal_ticket_field_value_container">
              <input
                type="text"
                maxLength="256"
                name="Ticket_Contact_Email"
                id="Ticket_Contact_Email"
                className={
                  editMode
                    ? "portal_ticket_field_value_editable"
                    : "portal_ticket_field_value"
                }
                defaultValue={ticket.Ticket_Contact_Email}
                readOnly={!editMode}
                onChange={handleEdits}
              />
            </div>
          </div>
        </div>
        <div className="portal_ticket_detail_right_container">
          <div className="portal_ticket_detail_row">
            <div className="portal_ticket_field_name">STATUS:</div>
            <div className="portal_ticket_field_value_container">
              <select
                id="Ticket_Status"
                name="Ticket_Status"
                onChange={handleEdits}
                defaultValue={ticket.Ticket_Status}
                className={
                  editMode
                    ? "portal_ticket_field_value_editable"
                    : "portal_ticket_field_value"
                }
                disabled={!editMode}
              >
                <option value="Unassigned">Unassigned</option>
                <option value="Working">Working</option>
                <option value="Pending Customer">Pending Customer</option>
                <option value="Pending Vendor">Pending Vendor</option>
                <option value="On Hold">On Hold</option>
                <option value="Closed">Closed</option>
              </select>
            </div>
          </div>
          <div className="portal_ticket_detail_row">
            <div className="portal_ticket_field_name">CREATED:</div>
            <div className="portal_ticket_field_value">
              {FormatDateTime(ticket.createdAt)}
            </div>
          </div>
          <div className="portal_ticket_detail_row">
            <div className="portal_ticket_field_name">UPDATED:</div>
            <div className="portal_ticket_field_value">
              {FormatDateTime(ticket.updatedAt)}
            </div>
          </div>
          <div className="portal_ticket_detail_row">
            <div className="portal_ticket_field_name">CLOSED:</div>
            <div className="portal_ticket_field_value">
              {ticket.Ticket_Closed_Date !== null
                ? FormatDateTime(ticket.Ticket_Closed_Date)
                : "TBD"}
            </div>
          </div>
          <div className="portal_ticket_detail_row">
            <div className="portal_ticket_field_name">TOPIC:</div>
            <div className="portal_ticket_field_value_container">
              <select
                id="Ticket_Topic"
                name="Ticket_Topic"
                onChange={handleEdits}
                className={
                  editMode
                    ? "portal_ticket_field_value_editable"
                    : "portal_ticket_field_value"
                }
                defaultValue={ticket.Ticket_Topic}
                disabled={!editMode}
              >
                <option value="">Select one...</option>
                <option value="Voice">Voice</option>
                <option value="Internet">Internet</option>
                <option value="Cellular Internet">Cellular Internet</option>
                <option value="SD WAN">SD WAN</option>
                <option value="Other">Other</option>
              </select>
            </div>
            {/* <div className="portal_ticket_field_value">
              {ticket.Ticket_Topic}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketTopCard;
