import {
  CREATE_USER,
  DELETE_USER,
  EDIT_USER,
  GET_USERS,
  GET_USER,
} from "../types";

const alertReducer = (state, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case DELETE_USER:
      return {
        ...state,
        // TO BE CREATED
      };
    case CREATE_USER:
      return {
        ...state,
        // TO BE CREATED
      };
    case EDIT_USER:
      return {
        ...state,
        // TO BE CREATED
      };
    case GET_USER:
      return {
        ...state,
        // TO BE CREATED
      };
    default:
      return state;
  }
};

export default alertReducer;
