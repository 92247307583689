import React, { useReducer } from "react";
import UsersContext from "./usersContext";
import usersReducer from "./usersReducer";
import {
  FetchUsers,
  FetchUser,
  DeleteUser,
  EditUser,
  CreateUser,
} from "../../functions/Users";

import {
  CREATE_USER,
  DELETE_USER,
  EDIT_USER,
  GET_USERS,
  GET_USER,
} from "../types";

const UserState = (props) => {
  const initialState = {
    users: [],
    user: "",
  };

  const [state, dispatch] = useReducer(usersReducer, initialState);

  const GetUsers = async () => {
    const fetchedUsers = await FetchUsers();

    dispatch({
      type: GET_USERS,
      payload: fetchedUsers,
    });
  };
  const GetAUser = async () => {
    const fetchedUser = await FetchUser();

    dispatch({
      type: GET_USER,
      payload: fetchedUser,
    });
  };

  const DeleteAUser = async (id) => {
    await DeleteUser(id);

    dispatch({
      type: DELETE_USER,
      payload: id,
    });
  };
  const EditAUser = async (id) => {
    await EditUser(id);

    dispatch({
      type: EDIT_USER,
      payload: id,
    });
  };
  const CreateAUser = async (user) => {
    const res = await await CreateUser(user);

    dispatch({
      type: CREATE_USER,
      payload: res.data,
    });
  };

  return (
    <UsersContext.Provider
      value={{
        users: state.users,
        user: state.user,
        GetUsers,
        GetAUser,
        DeleteAUser,
        EditAUser,
        CreateAUser,
      }}
    >
      {props.children}
    </UsersContext.Provider>
  );
};

export default UserState;
