import axios from "axios";

export const FetchTicketNotes = async () => {
  let data = {};

  try {
    await axios.get("/api/ticketNotes/all").then((res) => {
      data = res.data;
    });
  } catch (err) {
    console.log(err);
    data = "error";
  }
  return data;
};

export const FilterTicketNotes = async (filter) => {
  let data = {};

  try {
    await axios.post("/api/ticketNotes/query", filter).then((res) => {
      data = res.data;
    });
  } catch (err) {
    console.log(err);
    data = "error";
  }
  return data;
};

export const DeleteTicketNote = async (id) => {
  let data = "";
  try {
    await axios.delete(`/api/ticketNotes/${id}`).then((res) => {
      data = "deleted";
    });
  } catch (err) {
    console.log(err);
    data = "Server Error Deleting Note";
  }

  return data;
};
